import { useState } from 'react';

const initialState = <T extends string>(keys: Array<T>) => keys.reduce((acc, curr) => ({ ...acc, [curr]: false }), {});

export const useChecked = <T extends string>(keys: Array<T>) => {
	// @ts-ignore
	const [checked, setChecked] = useState<Checked<T>>(initialState(keys));

	const onCheckedHandler = (key: T) => () => {
		setChecked((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	return { checked, onCheckedHandler };
};
