import type { FC } from 'react';
import React, { Suspense } from 'react';
import { RouterProvider as ReactRouterProvider } from 'react-router-dom';

import { Loader } from '@/components/ui';
import { appRouter } from '@/pages';

const Fallback = () => <Loader fullPage />;

export const RouterProvider: FC = () => (
	<Suspense fallback={<Fallback />}>
		<ReactRouterProvider router={appRouter} />
	</Suspense>
);
