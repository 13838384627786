import { useEffect, useState } from 'react';

export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(() => window.matchMedia(query).matches);
	useEffect(() => {
		const mq = window.matchMedia(query);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const handler = ({ matches }: { matches: any }) => setMatches(matches);

		handler(mq);
		mq.addListener(handler);
		// eslint-disable-next-line consistent-return
		return () => mq.removeListener(handler);
	}, [query]);

	return matches;
}
