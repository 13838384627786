import { useState } from 'react';

export function usePasswordTypes<Keys extends string>(initialState: PasswordTypes<Keys>) {
	const [passwordTypes, setPasswordTypes] = useState(initialState);

	const togglePasswordType = (key: Keys) => () => {
		setPasswordTypes((prev) => ({
			...prev,
			[key]: prev[key] === 'password' ? 'text' : 'password',
		}));
	};

	return { passwordTypes, togglePasswordType };
}
