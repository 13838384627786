import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CONFIG } from '@/shared/config';
import type { RootState } from '@/store';

const baseQuery = fetchBaseQuery({
	baseUrl: CONFIG.BASE_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const { token } = (getState() as RootState).auth;

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		return headers;
	},
});

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery,
	endpoints: () => ({}),
});
