import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PortalWithState } from 'react-portal';

import { Button } from '@/components/ui';
import { toggleAcceptCookies } from '@/entities';
import { animations } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/store';

export const AcceptCookies: FC = () => {
	const dispatch = useAppDispatch();

	const { acceptCookies, lang: locale } = useAppSelector((state) => state.auth);

	const links = useMemo(
		() => ({
			cookies: `/static/documents/policy/${locale}/Cookies.docx`,
		}),
		[locale],
	);

	const handleClose = () => {
		dispatch(toggleAcceptCookies({ acceptCookies: true }));
	};

	return (
		<PortalWithState>
			{() => (
				<AnimatePresence>
					{!acceptCookies && (
						<motion.div className="flex-center fixed bottom-0 z-50 w-full px-8" {...animations.variants.scale}>
							<div className="w-full space-y-5 rounded-t-xl bg-black-100 p-8 shadow-[0_0_12px_0_rgba(245,173,20,0.50)] xl:w-[50%]">
								<p className="text-center text-lg text-white">
									<FormattedMessage
										id="Мы используем необходимые файлы cookie, чтобы наш сайт работал. С вашего согласия мы также можем использовать необязательные файлы cookie для улучшения взаимодействия с пользователем и анализа трафика веб-сайта. Нажимая «Принять», вы соглашаетесь с использованием файлов cookie на нашем веб-сайте, как описано в нашей"
										defaultMessage="We use essential cookies to make our site work. With your consent, we may also use non-essential
						cookies to improve user experience and analyze website traffic. By clicking “Accept,“ you agree to our
						website's cookie use as described in our"
									/>{' '}
									<a
										href={links.cookies}
										target="_blank"
										className="whitespace-nowrap text-orange-100 hover:underline"
										rel="noreferrer"
									>
										<FormattedMessage
											id="Политике использования файлов cookie"
											defaultMessage="Cookie Policy"
										/>
									</a>
									.
								</p>
								<div className="flex-center mt-6">
									<Button variant="small" className="rounded-lg px-16 py-3 text-xl" onClick={handleClose}>
										<FormattedMessage id="accept" defaultMessage="Accept" />
									</Button>
								</div>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</PortalWithState>
	);
};
