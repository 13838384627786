import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@/components/shared';
import { Button } from '@/components/ui';
import { toggleProductVariantNotExist } from '@/entities';
import { CloseIcon } from '@/shared/static';
import { getVariantKey } from '@/shared/utils';
import { useAppDispatch, useAppSelector } from '@/store';

export const ProductVariantNotExist: FC = () => {
	const dispatch = useAppDispatch();

	const {
		ProductVariantNotExist: { open, props },
	} = useAppSelector((state) => state.portals);

	const closeModal = () => {
		dispatch(toggleProductVariantNotExist({ open: false, props: { product: null, selectedVariant: null } }));
	};

	return (
		<Modal open={Boolean(open && props && props.product && props.selectedVariant)}>
			<div className="relative w-full max-w-3xl rounded-2xl bg-white p-6 text-left shadow-xl transition-all tablet:px-10">
				<Button
					variant="small"
					className="group !absolute right-3 top-3 z-10 !bg-transparent p-[2px] hover:!bg-orange-200"
					onClick={closeModal}
				>
					<CloseIcon className="h-4 w-4 fill-orange-200 transition-colors group-hover:fill-white" />
				</Button>
				<div className="text-center">
					<h3 className="mb-2 text-3xl text-orange-100">
						<FormattedMessage id="modal.ooops" defaultMessage="Ooops..." />
					</h3>
					<p className="text-xl">
						<FormattedMessage
							id="modal.looks-like"
							defaultMessage="Looks like {item} with {keys} is out of stock."
							values={{
								item: <span className="text-orange-100">{props?.product?.title || 'the item'}</span>,
								keys: <span className="text-orange-100">{getVariantKey(props?.selectedVariant || {})}</span>,
							}}
						/>
					</p>
					<p className="text-xl">
						<FormattedMessage
							id="modal.select-other-characteristics"
							defaultMessage="Please select other characteristics or another product."
						/>
					</p>
				</div>
			</div>
		</Modal>
	);
};
