import type { FC } from 'react';
import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/layouts';
import { useAppSelector } from '@/store';

const LoginPage = lazy(() => import('./Login'));
const SignupPage = lazy(() => import('./Signup'));
const AccountPage = lazy(() => import('./Account'));
const ChangePasswordPage = lazy(() => import('./ChangePassword'));
const DeliveryPage = lazy(() => import('./Delivery'));
const StorePage = lazy(() => import('./Store/Store'));
const StoreItemPage = lazy(() => import('./Store/StoreItem'));
const EventsPage = lazy(() => import('./Events/Events'));
const EventsItemPage = lazy(() => import('./Events/EventItem'));
const GalleryPage = lazy(() => import('./Gallery'));
const AlbumsItemPage = lazy(() => import('./Albums/AlbumsItem'));
const CartPage = lazy(() => import('./Cart'));
const TicketsItemPage = lazy(() => import('./Tickets/TicketsItem'));
const HomePage = lazy(() => import('./Home'));
const AboutPage = lazy(() => import('./About'));
const NotFoundPage = lazy(() => import('./404'));

export const enum Pages {
	Login = '/login',
	Signup = '/signup',
	Account = '/account',
	ChangePassword = '/change-password',
	Delivery = '/delivery',
	Store = '/store',
	StoreItem = '/store/:id',
	Events = '/events',
	EventsItem = '/events/:id',
	Gallery = '/gallery',
	AlbumsItem = '/albums/:id',
	Cart = '/cart',
	TicketsItem = '/tickets/:id',
	Home = '/',
	About = '/about',
	NotFound = '/404',
}

type GuardedRouteProps = {
	redirectRoute: Pages;
	inverse?: true;
};

const GuardedRoute: FC<GuardedRouteProps> = ({ redirectRoute, inverse }): JSX.Element => {
	const { isAuthorized } = useAppSelector((state) => state.auth);

	if (inverse) {
		return isAuthorized ? <Navigate to={redirectRoute} replace /> : <Outlet />;
	}

	return isAuthorized ? <Outlet /> : <Navigate to={redirectRoute} replace />;
};

export const appRouter = createBrowserRouter([
	{
		element: <GuardedRoute redirectRoute={Pages.Account} inverse />,
		errorElement: <Navigate to={Pages.NotFound} replace />,
		children: [
			{
				element: <MainLayout />,
				children: [
					{
						path: Pages.Login,
						element: <LoginPage />,
					},
					{
						path: Pages.Signup,
						element: <SignupPage />,
					},
				],
			},
		],
	},
	{
		element: <GuardedRoute redirectRoute={Pages.Login} />,
		errorElement: <Navigate to={Pages.NotFound} replace />,
		children: [
			{
				element: <MainLayout />,
				children: [
					{
						path: Pages.Account,
						element: <AccountPage />,
					},
					{
						path: Pages.ChangePassword,
						element: <ChangePasswordPage />,
					},
					{
						path: Pages.TicketsItem,
						element: <TicketsItemPage />,
					},
					{
						path: Pages.Cart,
						element: <CartPage />,
					},
				],
			},
		],
	},
	{
		element: <MainLayout />,
		children: [
			{
				path: Pages.Home,
				element: <HomePage />,
			},
			{
				path: Pages.Store,
				element: <StorePage />,
			},
			{
				path: Pages.StoreItem,
				element: <StoreItemPage />,
			},
			{
				path: Pages.Events,
				element: <EventsPage />,
			},
			{
				path: Pages.EventsItem,
				element: <EventsItemPage />,
			},
			{
				path: Pages.Gallery,
				element: <GalleryPage />,
			},
			{
				path: Pages.AlbumsItem,
				element: <AlbumsItemPage />,
			},
			{
				path: Pages.Delivery,
				element: <DeliveryPage />,
			},
			{
				path: Pages.About,
				element: <AboutPage />,
			},
		],
	},
	{
		path: Pages.NotFound,
		element: <NotFoundPage />,
	},
]);
