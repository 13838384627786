import * as Yup from 'yup';

import { errors } from '@/shared/constants';

export const enum SearchTab {
	events = 'events',
	store = 'store',
}

export type TForm = {
	title: string;
};

export type FoundItem = {
	id: string;
	title: string;
	href: string;
};

export const initialValues: TForm = {
	title: '',
};

export const getValidationSchema = (locale: Locales) =>
	Yup.object().shape({
		title: Yup.string().required(errors.yup[locale].required),
	});
