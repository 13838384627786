import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { PortalWithState } from 'react-portal';

import { animations } from '@/shared/constants';
import { useAlert } from '@/shared/hooks';
import { CancelIcon, InfoIcon } from '@/shared/static';
import { useAppSelector } from '@/store';

export const Alert: FC = () => {
	const { resetAlert } = useAlert();
	const {
		Alert: { open, props },
	} = useAppSelector((state) => state.portals);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				resetAlert();
			}, 4000);
		}
	}, [open]);

	return (
		<PortalWithState>
			{() => (
				<AnimatePresence>
					{open && props && props.text && props.mode && (
						<motion.div className="flex-center fixed right-5 top-5 z-[1000] w-full px-4 md:block md:w-auto">
							<motion.div
								className={clsx(
									'relative min-w-[300px] max-w-[600px] rounded-md p-4',
									props.mode === 'error' && 'bg-red-500',
									props.mode === 'info' && 'bg-green-100',
								)}
								{...animations.variants.scale}
							>
								<div className="flex gap-x-3">
									<div className="h-[56px] w-[56px] shrink-0">
										{props.mode === 'error' ? (
											<CancelIcon />
										) : (
											<InfoIcon className="fill-transparent stroke-white" />
										)}
									</div>
									<p className="font-medium leading-7 text-white">{props.text}</p>
								</div>
							</motion.div>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</PortalWithState>
	);
};
