import type { EventListQuery, TEvent } from '@/entities';
import { baseApi } from '@/shared/api';
import type { PaginationResult } from '@/shared/types';

const eventApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		eventList: build.query<PaginationResult<TEvent>, EventListQuery>({
			query: (body) => ({
				url: '/event/list',
				method: 'POST',
				body,
			}),
		}),
		eventById: build.query<TEvent, ID>({
			query: ({ id }) => ({
				url: `/event/${id}`,
			}),
		}),
		eventLocations: build.query<Array<string>, void>({
			query: () => ({
				url: '/event/locations',
			}),
		}),
		eventMinMaxPrice: build.query<MinMaxPrice, void>({
			query: () => ({
				url: '/event/minmaxForPriceMax',
			}),
		}),
	}),
});

export const {
	useEventListQuery,
	useLazyEventListQuery,
	useEventByIdQuery,
	useEventLocationsQuery,
	useEventMinMaxPriceQuery,
} = eventApi;
