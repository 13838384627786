enum UserRole {
	Admin = 'admin',
	Customer = 'customer',
}

export type User = {
	id: string;
	name: string;
	email: string;
	isAdmin: boolean;
	role: UserRole;
};
