import type { BaseModel, Media, Product } from '@/entities';
import type { ListQuery } from '@/shared/types';

export type TLocation = {
	type: string;
	coordinates: [number, number];
};

export type TEvent = BaseModel & {
	title: string;
	descriptionText: string;
	startDate: string;
	endDate: string;
	location: TLocation;
	locationTitle: string;
	priceMin: number;
	priceMax: number;
	formatPriceMin: FormatPrice;
	formatPriceMax: FormatPrice;
	products: Array<Product>;
	medias: Array<Media>;
	status: Status;
	category: string;
};

export type EventListQuery = ListQuery & {
	startDate?: string;
	endDate?: string;
	category?: string;
	locationTitle?: string;
	title?: string;
	priceMaxFrom?: number;
	priceMaxTo?: number;
	lang?: 'en' | 'ru';
};

export type EventFilterParams = {
	price: [number, number] | undefined;
	locations: Array<string> | undefined;
	dates: Record<Keys<Dates>, Date | undefined>;
};

export enum EventTimeStatus {
	going = 'going',
	soon = 'soon',
	passed = 'passed',
}
