import type { Album, Media } from '@/entities';
import { baseApi } from '@/shared/api';
import type { ListQuery, PaginationResult } from '@/shared/types';

const galleryApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		galleryList: build.query<PaginationResult<Media>, ListQuery>({
			query: () => ({
				url: '/gallery/list',
				method: 'POST',
			}),
		}),
		albumList: build.query<PaginationResult<Album>, ListQuery>({
			query: () => ({
				url: '/album/list',
				method: 'POST',
			}),
		}),
		albumById: build.query<Album, ID>({
			query: ({ id }) => ({
				url: `/album/${id}`,
			}),
		}),
	}),
});

export const { useGalleryListQuery, useAlbumListQuery, useAlbumByIdQuery } = galleryApi;
