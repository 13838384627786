import type { IntlShape } from 'react-intl';

import { Pages } from '@/pages';
import {
	HeaderAccountIcon,
	HeaderCartIcon,
	HeaderDeliveryIcon,
	HeaderSearchIcon,
	HeaderStoreIcon,
	HeaderTicketIcon,
} from '@/shared/static';

export const enum NavItemIds {
	store = 'store',
	buyTicket = 'buyTicket',
	delivery = 'delivery',
	cart = 'cart',
	search = 'search',
	account = 'account',
	login = 'login',
	signup = 'signup',
	auth = 'auth',
}

export type NavItem = {
	id: NavItemIds;
	title?: string;
	href?: Pages;
	icon?: SvgIcon;
};

export const getNavigationItems: (intl: IntlShape) => Array<NavItem> = (intl) => {
	return [
		{
			id: NavItemIds.store,
			icon: HeaderStoreIcon,
			title: intl.formatMessage({ id: 'store', defaultMessage: 'Store' }),
			href: Pages.Store,
		},
		{
			id: NavItemIds.buyTicket,
			icon: HeaderTicketIcon,
			title: intl.formatMessage({ id: 'buy-tickets', defaultMessage: 'Buy tickets' }),
			href: Pages.Events,
		},
		{
			id: NavItemIds.delivery,
			icon: HeaderDeliveryIcon,
			title: intl.formatMessage({ id: 'delivery', defaultMessage: 'Delivery' }),
			href: Pages.Delivery,
		},
		{
			id: NavItemIds.cart,
			icon: HeaderCartIcon,
			title: intl.formatMessage({ id: 'cart', defaultMessage: 'Cart' }),
			href: Pages.Cart,
		},
		{ id: NavItemIds.search, icon: HeaderSearchIcon },
		{
			id: NavItemIds.account,
			icon: HeaderAccountIcon,
			title: intl.formatMessage({ id: 'account', defaultMessage: 'Account' }),
			href: Pages.Account,
		},
		{
			id: NavItemIds.login,
			title: intl.formatMessage({ id: 'login', defaultMessage: 'Login' }),
			href: Pages.Login,
		},
		{
			id: NavItemIds.signup,
			title: intl.formatMessage({ id: 'sign-up', defaultMessage: 'Sign up' }),
			href: Pages.Signup,
		},
	];
};

export const navigationConfig: Indexed<Array<NavItemIds>, Exclude<Pages, Pages.NotFound>> = {
	[Pages.Store]: [NavItemIds.delivery, NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.auth, NavItemIds.search],
	[Pages.StoreItem]: [NavItemIds.cart, NavItemIds.store, NavItemIds.buyTicket, NavItemIds.auth, NavItemIds.search],
	[Pages.Events]: [NavItemIds.cart, NavItemIds.store, NavItemIds.auth, NavItemIds.search],
	[Pages.EventsItem]: [NavItemIds.cart, NavItemIds.store, NavItemIds.auth, NavItemIds.search],
	[Pages.TicketsItem]: [NavItemIds.cart, NavItemIds.store, NavItemIds.auth, NavItemIds.search],
	[Pages.Gallery]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.AlbumsItem]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.Delivery]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.Cart]: [NavItemIds.store, NavItemIds.buyTicket, NavItemIds.auth, NavItemIds.search],
	[Pages.Login]: [NavItemIds.delivery, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.Signup]: [NavItemIds.delivery, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.Account]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.ChangePassword]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
	[Pages.Home]: [NavItemIds.buyTicket, NavItemIds.store, NavItemIds.auth, NavItemIds.search],
	[Pages.About]: [NavItemIds.cart, NavItemIds.buyTicket, NavItemIds.store, NavItemIds.search],
};
