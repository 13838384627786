import type { Product, ProductListQuery } from '@/entities';
import { baseApi, TOGGLE_FAVORITES } from '@/shared/api';
import type { PaginationResult } from '@/shared/types';

const productApi = baseApi.enhanceEndpoints({ addTagTypes: [TOGGLE_FAVORITES] }).injectEndpoints({
	endpoints: (build) => ({
		productList: build.query<PaginationResult<Product>, ProductListQuery>({
			query: (body) => ({
				url: '/product/list',
				method: 'POST',
				body,
			}),
		}),
		productById: build.query<Product, ID>({
			query: ({ id }) => ({
				url: `/product/${id}`,
			}),
			providesTags: [TOGGLE_FAVORITES],
		}),
		productCategories: build.query<Array<string>, void>({
			query: () => ({
				url: '/product/categories',
			}),
		}),
		productMinMaxPrice: build.query<MinMaxPrice, void>({
			query: () => ({
				url: '/product/minmax',
			}),
		}),
	}),
});

export const {
	useProductListQuery,
	useLazyProductListQuery,
	useProductByIdQuery,
	useProductCategoriesQuery,
	useProductMinMaxPriceQuery,
} = productApi;
