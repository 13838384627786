import { baseApi } from '@/shared/api';

import type { LoginPayload, LoginResponse, SignupPayload, SignupResponse } from './auth.types';

export const authApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		authLogin: build.mutation<LoginResponse, LoginPayload>({
			query: (body) => ({
				url: '/auth/login',
				method: 'POST',
				body,
			}),
		}),
		authSignup: build.mutation<SignupResponse, SignupPayload>({
			query: (body) => ({
				url: '/auth/signup',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useAuthLoginMutation, useAuthSignupMutation } = authApi;
