import { isNil } from '@/shared/utils';

const getEnvVar = (value: string | undefined, key: string) => {
	if (isNil(value) && isNil(defaultValues[key])) {
		throw new Error(`Env variable ${key} is required`);
	}

	return value || defaultValues[key];
};

const defaultValues: Indexed<string> = {
	BASE_API_URL: 'http://localhost:3001',
	WEBTOKEN_EXPIRATION_TIME: '7890000',
	PRODUCTION_HOST: 'imonazanzibar.com',
	PRODUCTION_EMAIL: 'info@imonazanzibar.com',
	PRODUCTION_PHONE: '+255776751075',
};

export const CONFIG = {
	BASE_API_URL: `${getEnvVar(process.env.BASE_API_URL, 'BASE_API_URL')}/api`,
	WEBTOKEN_EXPIRATION_TIME: parseInt(getEnvVar(process.env.WEBTOKEN_EXPIRATION_TIME, 'WEBTOKEN_EXPIRATION_TIME'), 10),
	PRODUCTION_HOST: getEnvVar(process.env.PRODUCTION_HOST, 'PRODUCTION_HOST'),
	PRODUCTION_EMAIL: getEnvVar(process.env.PRODUCTION_EMAIL, 'PRODUCTION_EMAIL'),
	PRODUCTION_PHONE: getEnvVar(process.env.PRODUCTION_PHONE, 'PRODUCTION_PHONE'),
};
