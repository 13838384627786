import type { AlertMode } from '@/entities';
import { toggleAlert } from '@/entities';
import type { ApiError } from '@/shared/types';
import { getApiErrorMsg } from '@/shared/utils';
import { useAppDispatch, useAppSelector } from '@/store';

export const useAlert = () => {
	const dispatch = useAppDispatch();

	const locale = useAppSelector((state) => state.auth.lang);

	const openAlert = ({ text, error, mode = 'error' }: { text: string; error?: ApiError; mode?: AlertMode }) => {
		if (error) {
			text = getApiErrorMsg(error, locale);
		}
		dispatch(toggleAlert({ open: true, props: { text, mode } }));
	};

	const resetAlert = () => {
		dispatch(toggleAlert({ open: false, props: { text: null, mode: null } }));
	};

	return { openAlert, resetAlert };
};
