const yup = {
	eng: {
		required: 'The field is required',
		email: 'Email entered incorrectly',
		min8: 'The field cannot be less than 8 characters',
		withoutDigits: 'The field cannot contain digits',
		passwordMatch: 'Passwords must match',
	},
	ru: {
		required: 'Поле обязательно для заполнения',
		email: 'Почта введена некорректно',
		min8: 'Поле не может быть меньше 8 символов',
		withoutDigits: 'Поле не может содержать цифры',
		passwordMatch: 'Пароли должны совпадать',
	},
};

const http = {
	eng: {
		default: 'An error has occurred. Please check that the information you entered is correct and try again.',
	},
	ru: {
		default: 'Произошла ошибка. Пожалуйста, проверьте правильность введенной информации и повторите попытку.',
	},
};

export const errors = {
	yup,
	http,
};
