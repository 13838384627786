import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistedStore, store } from '@/store';

export const StoreProvider: FC<PropsWithChildren> = ({ children }) => (
	<ReduxProvider store={store}>
		<PersistGate loading={null} persistor={persistedStore}>
			{children}
		</PersistGate>
	</ReduxProvider>
);
