import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import { Loader } from '@/components/ui';

type Props = {
	condition: boolean;
	classes?: string;
} & PropsWithChildren;

export const LoadingWrapper: FC<Props> = ({ condition, children, classes = '' }) => {
	if (condition) {
		return <Loader center color="white" className={classes} />;
	}
	return <>{children}</>;
};
