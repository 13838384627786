import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from '@/entities';
import { authSlice } from '@/entities/auth/auth.slice';
import { baseApi } from '@/shared/api';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [authSlice.name],
};

const makeStore = () => {
	const store = configureStore({
		reducer: persistReducer(persistConfig, rootReducer) as unknown as typeof rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}).concat(baseApi.middleware),
	});

	setupListeners(store.dispatch);

	return store;
};

export const store = makeStore();
export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
