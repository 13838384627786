import type { BaseModel } from '@/entities';

export enum MediaType {
	image = 'image',
	video = 'video',
}

export type Photo = {
	_id: string;
	title: string;
	uri: string;
	type: MediaType;
};

export type Video = {
	_id: string;
	title: string;
	iframe: string;
	videoId: string;
	type: MediaType;
};

export type Media = Photo | Video;

export type Album = BaseModel & {
	title: string;
	medias: Array<Media>;
	status: Status;
};
