import clsx from 'clsx';
import React from 'react';

import { EyeClosedIcon, EyeOpenIcon } from '@/shared/static';

type Props<Keys extends string> = {
	typeKey: Keys;
	passwordTypes: PasswordTypes<Keys>;
	togglePasswordType: (key: Keys) => () => void;
	error: boolean;
};

export function TogglePasswordButton<Keys extends string>({
	typeKey,
	passwordTypes,
	togglePasswordType,
	error,
}: Props<Keys>) {
	return (
		<button type="button" onClick={togglePasswordType(typeKey)} className="group" tabIndex={-1}>
			{passwordTypes[typeKey] === 'password' ? (
				<EyeClosedIcon className={clsx('transition-opacity group-hover:opacity-100', error && 'stroke-red-200')} />
			) : (
				<EyeOpenIcon className={clsx('transition-opacity group-hover:opacity-100', error && 'stroke-red-200')} />
			)}
		</button>
	);
}
