import type { CalculatorRequestPayload, Favorite } from '@/entities';
import type { AviasalesItem } from '@/entities/calculator/calculator.types';
import { baseApi } from '@/shared/api';

export const calculatorApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		calculatorAviasalesPrices: build.query<{ data: Array<AviasalesItem> }, { departure_at: string }>({
			query: (params) => ({
				url: '/aviasales/prices',
				params,
			}),
		}),
		calculatorRequest: build.mutation<Favorite, CalculatorRequestPayload>({
			query: (body) => ({
				url: '/calculator/request',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useLazyCalculatorAviasalesPricesQuery, useCalculatorRequestMutation } = calculatorApi;
