import type { FC } from 'react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useScrollTo } from '@/shared/hooks';

import { Footer } from './Footer';
import { Header } from './Header/Header';

export const MainLayout: FC = () => {
	const { pathname } = useLocation();

	useScrollTo(pathname);

	return (
		<div className="flex min-h-screen w-screen flex-col">
			<Header />
			<div className="flex grow flex-col bg-white">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};
