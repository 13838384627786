import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

type Props = {
	open: boolean;
	className?: string;
} & PropsWithChildren;

const variants = {
	open: {
		height: 'auto',
		display: 'block',
		marginTop: '20px',
		transition: {
			height: {
				duration: 0.2,
			},
			marginTop: {
				duration: 0.2,
			},
		},
	},
	closed: {
		height: 0,
		marginTop: 0,
		transition: {
			height: {
				duration: 0.2,
			},
			marginTop: {
				duration: 0.2,
			},
		},
		transitionEnd: {
			display: 'none',
		},
	},
};

export const Disclosure: FC<Props> = ({ open, className = '', children }) => {
	return (
		<div className={clsx('w-full overflow-hidden')}>
			<motion.div initial={false} animate={open ? 'open' : 'closed'} variants={variants} className={className}>
				{children}
			</motion.div>
		</div>
	);
};
