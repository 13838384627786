import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { IntlProvider } from 'react-intl';

import messages_eng from '@/public/static/locales/eng.json';
import messages_ru from '@/public/static/locales/ru.json';
import { useAppSelector } from '@/store';

const messages = {
	[Locales.eng]: messages_eng,
	[Locales.ru]: messages_ru,
};

export const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
	const locale = useAppSelector((state) => state.auth.lang);

	return (
		// @ts-ignore
		<IntlProvider locale={locale || Locales.eng} messages={messages[locale || Locales.eng]}>
			{children}
		</IntlProvider>
	);
};
