import type { FC } from 'react';
import React from 'react';

import { Button } from '@/components/ui';
import { MinusIcon, PlusIcon } from '@/shared/static';

type Props = {
	title: string;
	open: boolean;
	toggle: () => void;
};

export const FilterButton: FC<Props> = ({ title, open, toggle }) => (
	<Button variant="small" className="inline-flex items-center justify-center space-x-2 px-5 py-1" onClick={toggle}>
		<span>{title}</span>
		<div className="h-4 w-4">{open ? <MinusIcon /> : <PlusIcon />}</div>
	</Button>
);
