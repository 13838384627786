import type { FormikErrors, FormikTouched } from 'formik';

import { get } from '@/shared/utils';

export const getError = <T extends Indexed>(touched: FormikTouched<T>, errors: FormikErrors<T>, field: string) => {
	const touchedValue = get(touched, field);
	const errorValue = get(errors, field);

	return {
		error: Boolean(touchedValue && errorValue),
		helperText: (touchedValue && errorValue) as string,
	};
};
