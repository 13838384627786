import type { ChangePasswordPayload, Favorite, User } from '@/entities';
import { baseApi, TOGGLE_FAVORITES } from '@/shared/api';
import type { ListQuery, PaginationResult } from '@/shared/types';

export const profileApi = baseApi.enhanceEndpoints({ addTagTypes: [TOGGLE_FAVORITES] }).injectEndpoints({
	endpoints: (build) => ({
		profileChangePassword: build.mutation<User, ChangePasswordPayload>({
			query: (body) => ({
				url: '/profile/changePassword',
				method: 'POST',
				body,
			}),
		}),
		profileFavorites: build.query<PaginationResult<Favorite>, ListQuery>({
			query: (body) => ({
				url: '/profile/favorites',
				method: 'POST',
				body,
			}),
			providesTags: [TOGGLE_FAVORITES],
		}),
		profileAddToFavorites: build.mutation<Favorite, { body: { productId: string } }>({
			query: ({ body }) => ({
				url: '/profile/addToFavorite',
				method: 'POST',
				body,
			}),
			invalidatesTags: [TOGGLE_FAVORITES],
		}),
		profileDeleteFromFavorites: build.mutation<Favorite, ID>({
			query: ({ id }) => ({
				url: `/profile/favorites/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: [TOGGLE_FAVORITES],
		}),
	}),
});

export const {
	useProfileChangePasswordMutation,
	useProfileFavoritesQuery,
	useProfileAddToFavoritesMutation,
	useProfileDeleteFromFavoritesMutation,
} = profileApi;
