import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'react-datepicker/dist/react-datepicker.css';
import '@/app/styles/globals.css';
import 'aos/dist/aos.css';

import AOS from 'aos';
import type { FC } from 'react';
import React from 'react';

import { LocaleProvider } from '@/app/providers/LocaleProvider';
import { PortalsProvider } from '@/app/providers/PortalsProvider';
import { RouterProvider } from '@/app/providers/RouterProvider';
import { StoreProvider } from '@/app/providers/StoreProvider';

AOS.init({
	once: true,
});

export const App: FC = () => (
	<StoreProvider>
		<LocaleProvider>
			<PortalsProvider>
				<RouterProvider />
			</PortalsProvider>
		</LocaleProvider>
	</StoreProvider>
);
