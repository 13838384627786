import About1Img from '@/public/static/content/about/1.png';
import About2Img from '@/public/static/content/about/2.png';
import About3Img from '@/public/static/content/about/3.png';
import About4Img from '@/public/static/content/about/4.png';
import About5Img from '@/public/static/content/about/5.png';
import About6Img from '@/public/static/content/about/6.png';
import About7Img from '@/public/static/content/about/7.png';
import About8Img from '@/public/static/content/about/8.png';
import ArrowDownIcon from '@/public/static/content/arrow-down.svg';
import ArrowLeftIcon from '@/public/static/content/arrow-left.svg';
import ArrowLeftCircleIcon from '@/public/static/content/arrow-left-circle.svg';
import ArrowRightCircleIcon from '@/public/static/content/arrow-right-circle.svg';
import AviaSalesIcon from '@/public/static/content/aviasales.svg';
import CalculatorGuest1Icon from '@/public/static/content/calculator/guest1.svg';
import CalculatorGuest2Icon from '@/public/static/content/calculator/guest2.svg';
import CalculatorGuest3Icon from '@/public/static/content/calculator/guest3.svg';
import CalculatorGuest4Icon from '@/public/static/content/calculator/guest4.svg';
import CalculatorGuest5Icon from '@/public/static/content/calculator/guest5.svg';
import CalculatorStep1Icon from '@/public/static/content/calculator/step1.svg';
import CalculatorStep2Icon from '@/public/static/content/calculator/step2.svg';
import CalculatorStep5Icon from '@/public/static/content/calculator/step5.svg';
import CalculatorStep6Icon from '@/public/static/content/calculator/step6.svg';
import CalculatorWelcomeBgImg from '@/public/static/content/calculator/welcome-bg.png';
import CalendarIcon from '@/public/static/content/calendar.svg';
import CancelIcon from '@/public/static/content/cancel.svg';
import CheckIcon from '@/public/static/content/check.svg';
import CheckedIcon from '@/public/static/content/checked.svg';
import CloseIcon from '@/public/static/content/close.svg';
import DatePickerIcon from '@/public/static/content/datePicker.svg';
import EmptyAccountFavoritesImg from '@/public/static/content/empty/eng/account-favorites.png';
import EmptyAlbumsImg from '@/public/static/content/empty/eng/albums.png';
import EmptyDeliveryPageImg from '@/public/static/content/empty/eng/delivery-page.png';
import EmptyEventImg from '@/public/static/content/empty/eng/event.png';
import EmptyEventsImg from '@/public/static/content/empty/eng/events.png';
import EmptyPhotosImg from '@/public/static/content/empty/eng/photos.png';
import EmptyProductImg from '@/public/static/content/empty/eng/product.png';
import EmptyProductsImg from '@/public/static/content/empty/eng/products.png';
import EmptyAccountFavoritesRuImg from '@/public/static/content/empty/ru/account-favorites.png';
import EmptyAlbumsRuImg from '@/public/static/content/empty/ru/albums.png';
import EmptyDeliveryPageRuImg from '@/public/static/content/empty/ru/delivery-page.png';
import EmptyEventsRuImg from '@/public/static/content/empty/ru/events.png';
import EmptyPhotosRuImg from '@/public/static/content/empty/ru/photos.png';
import EmptyProductRuImg from '@/public/static/content/empty/ru/product.png';
import EmptyProductsRuImg from '@/public/static/content/empty/ru/products.png';
import BmwTripImg from '@/public/static/content/excursion/bmw-trip.png';
import CouplesTripImg from '@/public/static/content/excursion/couples-trip.png';
import KitesurfingTripImg from '@/public/static/content/excursion/kitesurfing-trip.png';
import RetreatTripImg from '@/public/static/content/excursion/retreat-trip.png';
import EyeClosedIcon from '@/public/static/content/eye-closed.svg';
import EyeOpenIcon from '@/public/static/content/eye-open.svg';
import FooterGlobusIcon from '@/public/static/content/footer/globus.svg';
import FooterLocationIcon from '@/public/static/content/footer/location.svg';
import FooterLogoIcon from '@/public/static/content/footer/logo.svg';
import FooterPhoneIcon from '@/public/static/content/footer/phone.svg';
import HeaderAccountIcon from '@/public/static/content/header/account.svg';
import HeaderCartIcon from '@/public/static/content/header/cart.svg';
import HeaderDeliveryIcon from '@/public/static/content/header/delivery.svg';
import HeaderSearchIcon from '@/public/static/content/header/search.svg';
import HeaderStoreIcon from '@/public/static/content/header/store.svg';
import HeaderTicketIcon from '@/public/static/content/header/ticket.svg';
import InfoIcon from '@/public/static/content/info.svg';
import LikeIcon from '@/public/static/content/like.svg';
import Like1Icon from '@/public/static/content/like1.svg';
import LocationIcon from '@/public/static/content/location.svg';
import LogoIcon from '@/public/static/content/logo.svg';
import MinusIcon from '@/public/static/content/minus.svg';
import MinusCircleIcon from '@/public/static/content/minus-circle.svg';
import NotFoundBgImg from '@/public/static/content/notFound/background.png';
import NotFoundCompassImg from '@/public/static/content/notFound/compass.png';
import NotFoundIcon from '@/public/static/content/notFound/icon.svg';
import PageHeaderAboutImg from '@/public/static/content/pageHeader/about.png';
import PageHeaderAccountImg from '@/public/static/content/pageHeader/account.jpg';
import PageHeaderAuthImg from '@/public/static/content/pageHeader/auth.jpg';
import PageHederCalcImg from '@/public/static/content/pageHeader/calculator.png';
import PageHeaderCartImg from '@/public/static/content/pageHeader/cart.jpg';
import PageHeaderDeliveryImg from '@/public/static/content/pageHeader/delivery.jpg';
import PageHeaderEventsImg from '@/public/static/content/pageHeader/events.jpg';
import PageHeaderGalleryImg from '@/public/static/content/pageHeader/gallery.jpg';
import PageHeaderPaymentImg from '@/public/static/content/pageHeader/payment.jpg';
import PageHeaderStoreImg from '@/public/static/content/pageHeader/store.jpg';
import PlayIcon from '@/public/static/content/play.svg';
import PlusIcon from '@/public/static/content/plus.svg';
import PlusCircleIcon from '@/public/static/content/plus-circle.svg';
import SectionGalleryBgImg from '@/public/static/content/sections/gallery-bg.png';
import SectionHomeNextIcon from '@/public/static/content/sections/next.svg';
import SectionHomePrevIcon from '@/public/static/content/sections/prev.svg';
import SectionRelatedEventsBgImg from '@/public/static/content/sections/related-events-bg.png';
import SectionStoreBgImg from '@/public/static/content/sections/store-bg.png';
import SectionStoreSmallBgImg from '@/public/static/content/sections/store-bg-small.png';
import TrashIcon from '@/public/static/content/trash.svg';

export {
	About1Img,
	About2Img,
	About3Img,
	About4Img,
	About5Img,
	About6Img,
	About7Img,
	About8Img,
	ArrowDownIcon,
	ArrowLeftCircleIcon,
	ArrowLeftIcon,
	ArrowRightCircleIcon,
	AviaSalesIcon,
	BmwTripImg,
	CalculatorGuest1Icon,
	CalculatorGuest2Icon,
	CalculatorGuest3Icon,
	CalculatorGuest4Icon,
	CalculatorGuest5Icon,
	CalculatorStep1Icon,
	CalculatorStep2Icon,
	CalculatorStep5Icon,
	CalculatorStep6Icon,
	CalculatorWelcomeBgImg,
	CalendarIcon,
	CancelIcon,
	CheckedIcon,
	CheckIcon,
	CloseIcon,
	CouplesTripImg,
	DatePickerIcon,
	EyeClosedIcon,
	EyeOpenIcon,
	FooterGlobusIcon,
	FooterLocationIcon,
	FooterLogoIcon,
	FooterPhoneIcon,
	HeaderAccountIcon,
	HeaderCartIcon,
	HeaderDeliveryIcon,
	HeaderSearchIcon,
	HeaderStoreIcon,
	HeaderTicketIcon,
	InfoIcon,
	KitesurfingTripImg,
	Like1Icon,
	LikeIcon,
	LocationIcon,
	LogoIcon,
	MinusCircleIcon,
	MinusIcon,
	NotFoundBgImg,
	NotFoundCompassImg,
	NotFoundIcon,
	PageHeaderAboutImg,
	PageHeaderAccountImg,
	PageHeaderAuthImg,
	PageHeaderCartImg,
	PageHeaderDeliveryImg,
	PageHeaderEventsImg,
	PageHeaderGalleryImg,
	PageHeaderPaymentImg,
	PageHeaderStoreImg,
	PageHederCalcImg,
	PlayIcon,
	PlusCircleIcon,
	PlusIcon,
	RetreatTripImg,
	SectionGalleryBgImg,
	SectionHomeNextIcon,
	SectionHomePrevIcon,
	SectionRelatedEventsBgImg,
	SectionStoreBgImg,
	SectionStoreSmallBgImg,
	TrashIcon,
};

export const EmptyImages = {
	eng: {
		accountFavorites: EmptyAccountFavoritesImg,
		albums: EmptyAlbumsImg,
		delivery: EmptyDeliveryPageImg,
		event: EmptyEventImg,
		events: EmptyEventsImg,
		product: EmptyProductImg,
		products: EmptyProductsImg,
		photos: EmptyPhotosImg,
	},
	ru: {
		accountFavorites: EmptyAccountFavoritesRuImg,
		albums: EmptyAlbumsRuImg,
		delivery: EmptyDeliveryPageRuImg,
		event: EmptyEventImg,
		events: EmptyEventsRuImg,
		product: EmptyProductRuImg,
		products: EmptyProductsRuImg,
		photos: EmptyPhotosRuImg,
	},
};
