import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import React, { useState } from 'react';

import { BlurWrapper } from '@/components/shared';
import { setLang } from '@/entities';
import { noop } from '@/shared/constants';
import { ArrowDownIcon } from '@/shared/static';
import { capitalize } from '@/shared/utils';
import { useAppDispatch, useAppSelector } from '@/store';

type Option = {
	id: Locales;
	title: string;
};

const options: Array<Option> = [
	{
		id: Locales.eng,
		title: 'English',
	},
	{
		id: Locales.ru,
		title: 'Русский',
	},
];

export const SelectLang: FC = () => {
	const dispatch = useAppDispatch();

	const locale = useAppSelector((state) => state.auth.lang);

	const [open, setOpen] = useState<boolean>(false);

	const chooseOption = (opt: Option) => () => {
		if (opt.id !== locale) {
			dispatch(setLang(opt.id));
			setOpen(false);
			window.location.reload();
		}
	};

	return (
		<BlurWrapper className="relative z-10" onBlur={open ? () => setOpen(false) : noop}>
			<button
				type="button"
				className="group flex w-full items-center text-left transition-all"
				onClick={() => setOpen((prev) => !prev)}
			>
				<span className="block truncate transition-colors group-hover:text-orange-100">{capitalize(locale)}</span>
				<span className="pointer-events-none ml-[6px] mt-[2px]">
					<ArrowDownIcon
						className={clsx(
							'h-4 w-4 stroke-white transition-all group-hover:stroke-orange-100',
							open && 'rotate-180',
						)}
						aria-hidden="true"
					/>
				</span>
			</button>
			<AnimatePresence>
				{open && (
					<motion.div
						className="absolute right-0 mt-2 flex w-[120px] flex-col justify-center space-y-1 overflow-hidden rounded-lg bg-brown-100 px-2 shadow-[0_3px_4px_0_rgba(0,0,0,0.15)]"
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: '84px', opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{
							type: 'spring',
							stiffness: 260,
							damping: 20,
							duration: 0.3,
						}}
					>
						{options.map((opt) => (
							<button
								key={opt.id}
								type="button"
								className={clsx(
									'group block w-full rounded-md px-2 py-1 text-left transition-colors',
									locale === opt.id ? 'cursor-default bg-orange-100' : 'cursor-pointer hover:text-orange-100',
								)}
								onClick={chooseOption(opt)}
							>
								<span
									className={clsx(
										'truncate text-white transition-colors',
										locale === opt.id ? 'font-medium' : 'group-hover:text-orange-100',
									)}
								>
									{opt.title}
								</span>
							</button>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</BlurWrapper>
	);
};
