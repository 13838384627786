const transition = {
	spring: (duration = 0.3) => ({
		type: 'spring',
		stiffness: 260,
		damping: 20,
		duration,
	}),
};

export const animations = {
	variants: {
		opacity: {
			visible: { opacity: 1 },
			hidden: { opacity: 0 },
		},
		scale: {
			initial: {
				scale: 0,
				opacity: 0,
			},
			animate: {
				scale: 1,
				opacity: 1,
			},
			exit: {
				scale: 0,
				opacity: 0,
			},
			transition: transition.spring(),
		},
	},
};
