import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from '@/shared/api';

import type { AuthState } from './auth/auth.slice';
import { authSlice } from './auth/auth.slice';
import type { PortalsState } from './portals/portals.slice';
import { portalsSlice } from './portals/portals.slice';

type State = {
	[portalsSlice.name]: PortalsState;
	[authSlice.name]: AuthState;
	[baseApi.reducerPath]: any;
};

export const rootReducer = combineReducers<State>({
	[portalsSlice.name]: portalsSlice.reducer,
	// @ts-ignore
	[authSlice.name]: authSlice.reducer,
	[baseApi.reducerPath]: baseApi.reducer,
});
