import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import {
	AcceptCookies,
	Alert,
	FullImage,
	ProductHasVariants,
	ProductVariantNotExist,
	VideoPlayer,
} from '@/components/portals';

export const PortalsProvider: FC<PropsWithChildren> = ({ children }) => (
	<>
		<Alert />
		<AcceptCookies />
		<VideoPlayer />
		<ProductHasVariants />
		<ProductVariantNotExist />
		<FullImage />
		{children}
	</>
);
