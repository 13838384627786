import clsx from 'clsx';
import type { FC } from 'react';
import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';

type Props = {
	date: Date;
	changeDate: (date: Date) => void;
};

// @ts-ignore
const CustomInput = forwardRef<HTMLButtonElement>(({ value, onClick }, ref) => (
	<button
		className="w-full rounded-lg border border-brown-400 bg-white px-2 pb-[2px] pt-1 text-left"
		onClick={onClick}
		ref={ref}
		type="button"
	>
		<span className={clsx(value ? 'text-brown-400' : 'text-brown-400/50')}>{value || 'dd.mm.yyyy'}</span>
	</button>
));

export const DatePicker: FC<Props> = ({ date, changeDate }) => {
	const datePickerOnChange = (dateFromPicker: Date) => {
		changeDate(dateFromPicker);
	};

	return (
		<ReactDatePicker
			selected={date}
			onChange={datePickerOnChange}
			closeOnScroll
			calendarStartDay={1}
			customInput={<CustomInput />}
			dateFormat="dd.MM.yyyy"
		/>
	);
};
