import type { AddEventToCartPayload, AddProductToCartPayload, Cart } from '@/entities';
import { baseApi, CART_LIST } from '@/shared/api';

export const cartApi = baseApi.enhanceEndpoints({ addTagTypes: [CART_LIST] }).injectEndpoints({
	endpoints: (build) => ({
		cartGet: build.query<Cart, void>({
			query: () => ({
				url: '/cart',
			}),
			providesTags: [CART_LIST],
		}),
		cartAddProduct: build.mutation<Cart, AddProductToCartPayload>({
			query: ({ body }) => ({
				url: '/cart/addProduct',
				method: 'POST',
				body,
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartAddEvent: build.mutation<Cart, AddEventToCartPayload>({
			query: ({ body }) => ({
				url: '/cart/addEvent',
				method: 'POST',
				body,
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartChangeProductCount: build.mutation<Cart, { id: string; body: { count: number } }>({
			query: ({ id, body }) => ({
				url: `/cart/changeProductCount/${id}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartChangeEventCount: build.mutation<Cart, { id: string; body: { countMin: number; countMax: number } }>({
			query: ({ id, body }) => ({
				url: `/cart/changeEventCount/${id}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartRemoveProduct: build.mutation<Cart, ID>({
			query: ({ id }) => ({
				url: `/cart/removeProduct/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartRemoveEvent: build.mutation<Cart, ID>({
			query: ({ id }) => ({
				url: `/cart/removeEvent/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartClearProducts: build.mutation<Cart, void>({
			query: () => ({
				url: '/cart/clearProduct',
				method: 'POST',
			}),
			invalidatesTags: [CART_LIST],
		}),
		cartClearEvents: build.mutation<Cart, void>({
			query: () => ({
				url: '/cart/clearEvent',
				method: 'POST',
			}),
			invalidatesTags: [CART_LIST],
		}),
	}),
});

export const {
	useCartGetQuery,
	useCartAddProductMutation,
	useCartAddEventMutation,
	useCartChangeProductCountMutation,
	useCartChangeEventCountMutation,
	useCartRemoveProductMutation,
	useCartRemoveEventMutation,
	useCartClearProductsMutation,
	useCartClearEventsMutation,
} = cartApi;
