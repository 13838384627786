import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

type Props = {
	className?: string;
};

export const Divider: FC<Props> = ({ className = '' }) => {
	return <div className={clsx('h-[1px] w-full bg-brown-400', className)} />;
};
