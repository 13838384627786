import clsx from 'clsx';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { FormikWrapper } from '@/components/shared';
import { useLazyEventListQuery, useLazyProductListQuery } from '@/entities';
import { Pages } from '@/pages';
import { useAlert } from '@/shared/hooks';
import { CloseIcon, HeaderSearchIcon } from '@/shared/static';
import { replaceId } from '@/shared/utils';
import { useAppSelector } from '@/store';

import type { FoundItem, TForm } from './Search.const';
import { getValidationSchema, initialValues, SearchTab } from './Search.const';

type Props = {
	closeSearch: Noop;
};

type TabButtonProps = {
	id: SearchTab;
	title: string;
	activeTab: SearchTab;
	toggleActiveTab: (value: SearchTab) => Noop;
};

const TabButton: FC<TabButtonProps> = ({ id, title, activeTab, toggleActiveTab }) => (
	<button className="group basis-1/2 py-2" disabled={activeTab === id} onClick={toggleActiveTab(id)} type="button">
		<p
			className={clsx(
				'truncate transition-colors',
				activeTab === id ? 'text-orange-100' : 'group-hover:text-orange-100',
			)}
		>
			{title}
		</p>
	</button>
);

export const Search: FC<Props> = ({ closeSearch }) => {
	const intl = useIntl();

	const [fetchEvents] = useLazyEventListQuery();
	const [fetchProducts] = useLazyProductListQuery();

	const [foundItems, setFoundItems] = useState<Array<FoundItem>>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<SearchTab>(SearchTab.events);

	const { openAlert } = useAlert();

	const locale = useAppSelector((state) => state.auth.lang);

	const validationSchema = useMemo(() => getValidationSchema(locale), [locale]);

	const onSubmit = async ({ title }: TForm) => {
		try {
			if (activeTab === SearchTab.events) {
				const { data } = await fetchEvents({ title });
				if (data) {
					setFoundItems(data.docs.map((event) => ({ ...event, href: replaceId(Pages.EventsItem, event.id) })));
				}
			}
			if (activeTab === SearchTab.store) {
				const { data } = await fetchProducts({ title });
				if (data) {
					setFoundItems(
						data.docs.map((product) => ({ ...product, href: replaceId(Pages.StoreItem, product.id) })),
					);
				}
			}
			if (!isSubmitting) {
				setIsSubmitting(true);
			}
		} catch (error: any) {
			openAlert({ text: '', error });
		}
	};

	const toggleActiveTab = (value: SearchTab) => () => {
		setActiveTab(value);
	};

	const clickResultItemHandler = () => {
		closeSearch();
	};

	return (
		<div className="absolute top-0 w-full">
			<div className="customContainer bg-black-100 py-[14px]">
				<FormikWrapper
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(form) => onSubmit(form)}
					className="w-full"
				>
					{(getFieldProps, getFieldError) => (
						<div
							className={clsx(
								'flex items-center rounded-md border border-gray-300',
								getFieldError('title').error && 'border-red-500',
							)}
						>
							<input
								placeholder={intl.formatMessage({
									id: 'search.placeholder',
									defaultMessage: 'What are you looking for?',
								})}
								className={clsx(
									'block w-full bg-transparent py-2 pl-4 text-base text-white placeholder:text-gray-400 focus:outline-none focus:ring-0',
									getFieldError('title').error && 'text-red-500 placeholder:!text-red-500',
								)}
								{...getFieldProps('title')}
							/>
							<div className="flex space-x-2 px-2">
								<button className="group h-fit" type="submit">
									<HeaderSearchIcon className="transition-colors group-hover:fill-orange-100" />
								</button>
								<button className="group h-fit" type="button" onClick={closeSearch}>
									<CloseIcon className="h-6 w-6 transition-colors group-hover:fill-orange-100" />
								</button>
							</div>
						</div>
					)}
				</FormikWrapper>
			</div>
			<div className="h-[calc(100vh_-_70px)] bg-black-100/90">
				<div className="customContainer overflow-y-auto pb-6 pt-2">
					<div>
						<div className="h-[1px] bg-orange-500" />
						<div className="flex h-[52px] text-2xl text-white tablet:text-3xl">
							<TabButton
								id={SearchTab.events}
								title={intl.formatMessage({
									id: 'events',
									defaultMessage: 'Events',
								})}
								activeTab={activeTab}
								toggleActiveTab={toggleActiveTab}
							/>
							<div className="h-full w-[1px] bg-orange-500" />
							<TabButton
								id={SearchTab.store}
								title={intl.formatMessage({
									id: 'store',
									defaultMessage: 'Store',
								})}
								activeTab={activeTab}
								toggleActiveTab={toggleActiveTab}
							/>
						</div>
						<div className="h-[1px] bg-orange-500" />
					</div>
					<div>
						{!isSubmitting && (
							<div className="flex-center pt-8 text-2xl text-white">
								<p>
									<FormattedMessage id="search.default-text" defaultMessage="Enter a search request" />
								</p>
							</div>
						)}
						{isSubmitting && !foundItems.length && (
							<div className="flex-center pt-8 text-2xl text-white">
								<p>
									<FormattedMessage
										id="search.not-found-text"
										defaultMessage="Nothing found for your request"
									/>
								</p>
							</div>
						)}
						{isSubmitting &&
							!!foundItems.length &&
							foundItems.map((item) => (
								<Link key={item.id} to={item.href} className="group block" onClick={clickResultItemHandler}>
									<div className="test-center whitespace-nowrap p-3 text-xl text-white">
										<p className="truncate transition-colors group-hover:text-orange-100">{item.title}</p>
									</div>
									<div className="h-[1px] bg-orange-500" />
								</Link>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
