import clsx from 'clsx';
import type { FC } from 'react';
import React, { useState } from 'react';

import { Modal } from '@/components/shared';
import { Loader } from '@/components/ui';
import { toggleFullImage } from '@/entities';
import { CloseIcon } from '@/shared/static';
import { useAppDispatch, useAppSelector } from '@/store';

export const FullImage: FC = () => {
	const dispatch = useAppDispatch();

	const {
		FullImage: { open, props },
	} = useAppSelector((state) => state.portals);

	const [natural, setNatural] = useState<Nullable<{ width: number }>>(null);

	const closeModal = () => {
		dispatch(toggleFullImage({ open: false, props: { blob: null } }));
	};

	return (
		<Modal open={Boolean(open && props?.blob)}>
			{!natural && <Loader center containerClassName="absolute" />}
			<div className={clsx('customContainer flex-center h-full w-full transition-opacity', !natural && 'opacity-0')}>
				<button className="group !absolute right-3 top-3 z-10" onClick={closeModal} disabled={!natural}>
					<CloseIcon className="h-16 w-16 fill-white transition-colors group-hover:fill-orange-200" />
				</button>
				<img
					src={props!.blob!}
					alt="Image full"
					className="relative rounded-2xl !p-0 shadow-xl"
					style={{
						width: natural?.width || '100%',
						maxWidth: '100%',
						maxHeight: '80%',
					}}
					onLoad={(event) => {
						// @ts-ignore
						const { naturalWidth: width } = event.target;
						setNatural({ width });
					}}
				/>
			</div>
		</Modal>
	);
};
