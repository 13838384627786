import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@/components/shared';
import { Button } from '@/components/ui';
import { toggleProductHasVariants } from '@/entities';
import { CloseIcon } from '@/shared/static';
import { useAppDispatch, useAppSelector } from '@/store';

export const ProductHasVariants: FC = () => {
	const dispatch = useAppDispatch();

	const {
		ProductHasVariants: { open, props },
	} = useAppSelector((state) => state.portals);

	const closeModal = () => {
		dispatch(toggleProductHasVariants({ open: false, props: { product: null } }));
	};

	return (
		<Modal open={Boolean(open && props && props.product)}>
			<div className="relative w-full max-w-3xl rounded-2xl bg-white p-6 text-left shadow-xl transition-all tablet:px-10">
				<Button
					variant="small"
					className="group !absolute right-3 top-3 z-10 !bg-transparent p-[2px] hover:!bg-orange-200"
					onClick={closeModal}
				>
					<CloseIcon className="h-4 w-4 fill-orange-200 transition-colors group-hover:fill-white" />
				</Button>
				<div className="text-center">
					<h3 className="mb-2 text-3xl text-orange-100">
						<FormattedMessage id="modal.ooops" defaultMessage="Ooops..." />
					</h3>
					<p className="text-xl">
						<FormattedMessage
							id="modal.has-several-characteristics"
							defaultMessage="{item} has several characteristics."
							values={{
								item: <span className="text-orange-100">{props?.product?.title || 'The item'}</span>,
							}}
						/>
					</p>
					<p className="text-xl">
						<FormattedMessage
							id="modal.choose-the-ones"
							defaultMessage="Choose the ones you like on the product page."
						/>
					</p>
				</div>
			</div>
		</Modal>
	);
};
