import { DateTime } from 'luxon';

import type { Album, Photo, Product, TEvent, Video } from '@/entities';
import { EventTimeStatus, MediaType } from '@/entities';
import type { Pages } from '@/pages';
import { getStaticPath, getYoutubeCover } from '@/shared/constants';

export const isNil = (value: any): boolean => {
	return value === undefined || value === null;
};

export const values = (obj: Indexed): Array<unknown> => {
	return Object.entries(obj).map(([_, value]) => value);
};

export const get = (obj: Indexed<any>, path: string, defaultValue?: any) => {
	const keys = path.split('.');
	let result = obj;

	for (const key of keys) {
		result = result[key];

		if (result === undefined) {
			return defaultValue ?? result;
		}
	}

	return result;
};

export const omit = (obj: Indexed, keys: Array<string>) => {
	const res: Indexed = {};
	const set = new Set(keys);

	const keysToCopy = Object.keys(obj).filter((key) => !set.has(key));
	keysToCopy.forEach((key) => {
		res[key] = obj[key];
	});

	return res;
};

export const replaceId = (page: Pages, id: string) => page.replace(/:id/, id);

export const toggleBodyScrolling = (open: boolean) => {
	if (open) {
		document.body.classList.add('stop-scrolling');
	} else {
		document.body.classList.remove('stop-scrolling');
	}
};

export const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

export const shuffle = <T>(array: Array<T>) => {
	if (array.length <= 1) {
		return array;
	}

	const result = [...array];

	for (let i = result.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[result[i], result[j]] = [result[j], result[i]];
	}
	return result;
};

export const getVariantKey = (options: Indexed<string>) =>
	Object.entries(options)
		.map((tuple) => tuple.join(':'))
		.join(',');

export const getEventTimeStatus = (event: TEvent): EventTimeStatus => {
	const now = DateTime.now();
	const start = DateTime.fromISO(event.startDate);
	const end = DateTime.fromISO(event.endDate);

	if (Math.trunc(now.diff(end, ['days']).days) > 0) {
		return EventTimeStatus.passed;
	}

	if (Math.trunc(now.diff(start, ['days']).days) <= -1) {
		return EventTimeStatus.soon;
	}

	if (Math.trunc(now.diff(start, ['days']).days) > -1 && Math.trunc(now.diff(end, ['days']).days) <= 0) {
		return EventTimeStatus.going;
	}

	return EventTimeStatus.passed;
};

export const filterRelatedEvents = (events: Array<TEvent>, count = 3) =>
	shuffle(events.filter((e) => getEventTimeStatus(e) === EventTimeStatus.passed)).slice(0, count);

export const filterRecEvents = (events: Array<TEvent>) => {
	const eventsGoingNow = events.filter((e) => getEventTimeStatus(e) === EventTimeStatus.going);
	const recEventsFromDbTemp = [...eventsGoingNow];
	if (eventsGoingNow.length < 3) {
		const eventsSoon = events.filter((e) => getEventTimeStatus(e) === EventTimeStatus.soon);
		recEventsFromDbTemp.push(...eventsSoon);
	}
	return shuffle(recEventsFromDbTemp).slice(0, 3);
};

export const sortByTypeCb = (a: { type: string }, b: { type: string }) => {
	if (a.type < b.type) {
		return -1;
	}

	if (a.type > b.type) {
		return 1;
	}

	return 0;
};

export const getCover = ({ type, entity }: { type: StaticType; entity: Product | TEvent | Album }): string => {
	if (!entity.medias.length) {
		return '';
	}

	const first = entity.medias[0];

	if (first.type === MediaType.video) {
		return getYoutubeCover({ videoId: (first as Video).videoId });
	}

	return getStaticPath({ type, path: (first as Photo).uri });
};
