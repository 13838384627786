import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { User } from '@/entities';

import { authApi } from './auth.api';

export type AuthState =
	| {
			isAuthorized: false;
			acceptCookies: boolean;
			lang: Locales;
			user?: Nullable<User>;
			token?: string;
	  }
	| {
			isAuthorized: true;
			acceptCookies: boolean;
			lang: Locales;
			user: Nullable<User>;
			token: string;
	  };

const initialState: AuthState = {
	isAuthorized: false,
	acceptCookies: false,
	lang: Locales.eng,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.isAuthorized = false;
			state.user = undefined;
			state.token = undefined;
		},
		toggleAcceptCookies: (state, { payload }: PayloadAction<{ acceptCookies: boolean }>) => {
			state.acceptCookies = payload.acceptCookies;
		},
		setLang: (state, action: PayloadAction<Locales>) => {
			state.lang = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			authApi.endpoints.authLogin.matchFulfilled,
			(state: AuthState, { payload: { token, user } }) => {
				state.isAuthorized = true;
				state.user = user;
				state.token = token;
			},
		);
		builder.addMatcher(
			authApi.endpoints.authSignup.matchFulfilled,
			(state: AuthState, { payload: { token, user } }) => {
				state.isAuthorized = true;
				state.user = user;
				state.token = token;
			},
		);
	},
});

export const { logout, setLang, toggleAcceptCookies } = authSlice.actions;
