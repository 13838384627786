import { Form, FormikProvider, useFormik } from 'formik';
import type { ReactElement } from 'react';
import React from 'react';

import type { FormikWrapperProps } from './FormikWrapper.types';
import { getError } from './FormikWrapper.utils';

export function FormikWrapper<TForm extends Indexed>({
	initialValues,
	validationSchema,
	onSubmit,
	children,
	className = '',
}: FormikWrapperProps<TForm>): ReactElement<FormikWrapperProps<TForm>> {
	const formik = useFormik<TForm>({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const { handleSubmit, getFieldProps, errors, touched, submitCount } = formik;

	const getFieldError = (field: Keys<TForm>) => getError<TForm>(touched, errors, field as string);

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit} className={className}>
				{children(getFieldProps, getFieldError, submitCount > 0)}
			</Form>
		</FormikProvider>
	);
}
