import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

import type { InputProps } from './Input.types';

export const Input: FC<InputProps> = ({
	type,
	name,
	onChange,
	value,
	placeholder,
	error,
	helperText,
	min,
	max,
	iconEnd,
	rows,
	disabled = false,
	mode = 'input',
	variant = 'main',
	className = '',
}) => {
	if (mode === 'textarea') {
		return (
			<div className={className}>
				<textarea
					id={name}
					name={name}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					rows={rows}
					disabled={disabled}
					className={clsx(
						'focus:ring-none relative block w-full rounded-lg border border-brown-400/70 px-3 py-2 leading-4 text-brown-400 placeholder:text-brown-400/50 focus:outline-none',
						error && 'border-red-900 text-red-900 placeholder:!text-red-900',
					)}
				/>
				{helperText && (
					<p
						className={clsx(
							'mt-[2px] text-sm text-red-900',
							variant === 'main' && 'ml-4',
							variant === 'formItem' && 'ml-3',
						)}
					>
						<span className="font-medium">{helperText}</span>
					</p>
				)}
			</div>
		);
	}

	return (
		<div className={className}>
			<div
				className={clsx(
					'relative block w-full rounded-lg border bg-transparent text-brown-400',
					error && '!border-red-900 !text-red-900',
					variant === 'main' && 'border-brown-400 text-lg',
					variant === 'formItem' && 'border-brown-400/50',
					variant === 'calculator' && 'border-brown-400/50 text-lg',
				)}
			>
				<input
					className={clsx(
						'focus:ring-none w-full rounded-lg placeholder:text-brown-400/50 focus:outline-none',
						error && 'placeholder:!text-red-900',
						variant === 'main' && 'px-4 py-2 placeholder:text-lg',
						variant === 'formItem' && 'px-3 py-1',
						variant === 'calculator' && 'bg-beige-100 px-4 py-2 placeholder:text-lg',
					)}
					id={name}
					name={name}
					type={type || 'text'}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					min={min}
					max={max}
					disabled={disabled}
				/>
				{iconEnd && <span className="absolute inset-y-0 right-6 flex items-center">{iconEnd}</span>}
			</div>
			{helperText && (
				<p
					className={clsx(
						'mt-[2px] text-sm text-red-900',
						variant === 'main' && 'ml-4',
						variant === 'formItem' && 'ml-3',
						variant === 'calculator' && 'ml-4',
					)}
				>
					<span className="font-semibold">{helperText}</span>
				</p>
			)}
		</div>
	);
};
