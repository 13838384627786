import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

import { CheckedIcon } from '@/shared/static';

type Props = {
	id: string;
	checked: boolean | undefined;
	onChange: Noop;
	label?: string;
	error?: boolean;
	styles?: {
		container?: string;
		labelStyle?: string;
		unchecked?: string;
		inputStyle?: string;
		size?: string;
		iconSize?: string;
	};
};

export const Checkbox: FC<Props> = ({ id, label, checked, onChange, error = false, styles }) => {
	const { container = '', size = '', iconSize = '', unchecked = '', labelStyle = '', inputStyle = '' } = styles || {};

	return (
		<div className={clsx('flex w-fit items-center', container)}>
			<input
				id={`checkbox-${id}`}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				className={clsx('absolute cursor-pointer opacity-0', size || 'h-6 w-6')}
			/>
			<div
				className={clsx(
					'flex flex-shrink-0 items-center justify-center transition-all',
					size || 'h-6 w-6',
					inputStyle || 'rounded border',
					unchecked || 'border-brown-400/50 bg-transparent',
					checked && '!border-transparent !bg-orange-100',
					error && '!border-red-900',
				)}
			>
				<CheckedIcon
					className={clsx(
						'pointer-events-none transition-opacity',
						iconSize || 'h-3 w-3',
						checked ? 'opacity-100' : 'opacity-0',
					)}
				/>
			</div>
			{label && (
				<label
					htmlFor={`checkbox-${id}`}
					className={clsx(
						'ml-3 cursor-pointer transition-colors',
						error && '!text-red-900',
						labelStyle || 'text-lg text-brown-400',
					)}
				>
					{label}
				</label>
			)}
		</div>
	);
};
