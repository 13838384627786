import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Pages } from '@/pages';
import { CONFIG } from '@/shared/config';
import { FooterGlobusIcon, FooterLocationIcon, FooterLogoIcon, FooterPhoneIcon } from '@/shared/static';

const getNavigation = (intl: IntlShape) => [
	{
		title: intl.formatMessage({ id: 'about-us', defaultMessage: 'About us' }),
		href: Pages.About,
	},
	{
		title: intl.formatMessage({ id: 'events', defaultMessage: 'Events' }),
		href: Pages.Events,
	},
	{
		title: intl.formatMessage({ id: 'store', defaultMessage: 'Store' }),
		href: Pages.Store,
	},
	{
		title: intl.formatMessage({ id: 'gallery', defaultMessage: 'Gallery' }),
		href: Pages.Gallery,
	},
];

export const Footer: FC = () => {
	const intl = useIntl();

	const navigation = useMemo(() => getNavigation(intl), [intl]);

	return (
		<div className="bg-black-100">
			<div className="customContainer flex flex-col-reverse justify-between py-10 text-white mobile:flex-row-reverse tablet:py-14 desktop:flex-row">
				<div className="flex-center mt-10 mobile:mt-0">
					<FooterLogoIcon className="h-[75px] w-[280px] tablet:h-[95px] tablet:w-[350px]" />
				</div>
				<div className="flex flex-col-reverse desktop:flex-row desktop:gap-x-36">
					<div className="mt-6 desktop:mt-0">
						<h4 className="mb-4 text-3xl">
							<FormattedMessage id="navigation" defaultMessage="Navigation" />
						</h4>
						<nav>
							<ul className="flex space-x-4 desktop:flex-col desktop:space-x-0 desktop:space-y-4">
								{navigation.map((item, i) => (
									<li key={i}>
										<Link
											to={item.href}
											className="text-yellow-100 desktop:text-white desktop:transition-colors desktop:hover:text-orange-100"
										>
											{item.title}
										</Link>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div>
						<h4 className="mb-4 text-3xl">
							<FormattedMessage id="contacts" defaultMessage="Contacts" />
						</h4>
						<div>
							<ul className="space-y-4">
								<li className="flex items-center space-x-3">
									<FooterPhoneIcon />
									<a
										className="mt-[2px] transition-colors hover:text-orange-100"
										href={`tel:${CONFIG.PRODUCTION_PHONE}`}
									>
										{CONFIG.PRODUCTION_PHONE}
									</a>
								</li>
								<li className="flex items-center space-x-3">
									<FooterLocationIcon />
									<p className="whitespace-pre">
										Zanzibar 71102 P.O.Box 765{`\n`}
										Sheikha Abdullah Said
									</p>
								</li>
								<li className="flex items-center space-x-3">
									<FooterGlobusIcon />
									<div className="flex flex-col">
										<a
											className="transition-colors hover:text-orange-100"
											href={`https://${CONFIG.PRODUCTION_HOST}`}
											target="_blank"
											rel="noreferrer"
										>
											{CONFIG.PRODUCTION_HOST}
										</a>
										<a
											className="transition-colors hover:text-orange-100"
											href={`mailto:${CONFIG.PRODUCTION_EMAIL}`}
										>
											{CONFIG.PRODUCTION_EMAIL}
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
