import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';

import { Modal } from '@/components/shared';
import { Loader } from '@/components/ui';
import { toggleVideoPlayer } from '@/entities';
import { CloseIcon } from '@/shared/static';
import { useAppDispatch, useAppSelector } from '@/store';

export const VideoPlayer: FC = () => {
	const dispatch = useAppDispatch();

	const {
		VideoPlayer: { open, props },
	} = useAppSelector((state) => state.portals);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeModal = () => {
		dispatch(toggleVideoPlayer({ open: false, props: { videoId: null } }));
	};

	useEffect(() => {
		if (open) {
			setIsLoading(true);
		}
	}, [open]);

	return (
		<Modal open={Boolean(open && props?.videoId)}>
			{isLoading && <Loader center containerClassName="absolute absolute-center" />}
			<div className="customContainer flex-center h-[300px] max-w-3xl transition-opacity mobile:h-[400px]">
				<button className="group !absolute right-3 top-3 z-10" onClick={closeModal}>
					<CloseIcon className="h-16 w-16 fill-white transition-colors group-hover:fill-orange-200" />
				</button>
				<YouTube
					videoId={props!.videoId as string}
					opts={{
						height: '100%',
						width: '100%',
					}}
					onReady={() => setIsLoading(false)}
					className="relative z-50 h-full w-full"
					iframeClassName="rounded-2xl"
				/>
			</div>
		</Modal>
	);
};
