import './Loader.css';

import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

type Props = {
	center?: boolean;
	fullPage?: boolean;
	color?: 'orange' | 'white';
	className?: string;
	containerClassName?: string;
};

export const Loader: FC<Props> = ({
	center,
	fullPage,
	color = 'orange',
	className = 'w-[60px] h-[60px] border-[6px]',
	containerClassName = '',
}) => {
	const spinnerClasses = clsx('spinner', color === 'orange' ? 'spinnerOrange' : 'spinnerWhite', className);
	const containerClasses = (classes?: string) => clsx('flex-center', classes, containerClassName);

	const Spinner = () => <div className={spinnerClasses} />;

	if (center) {
		return <div className={containerClasses()}>{Spinner()}</div>;
	}

	if (fullPage) {
		return <div className={containerClasses('h-screen w-screen')}>{Spinner()}</div>;
	}

	return Spinner();
};
