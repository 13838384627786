import { AnimatePresence, motion } from 'framer-motion';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { PortalWithState } from 'react-portal';

import { animations } from '@/shared/constants';

type Props = PropsWithChildren & {
	open: boolean;
};

export const Modal: FC<Props> = ({ open, children }) => (
	<PortalWithState>
		{() => (
			<AnimatePresence>
				{open && (
					<div className="fixed top-0 z-[100] h-screen w-screen">
						<motion.div
							initial={{
								opacity: 0,
							}}
							animate={{
								opacity: 1,
							}}
							exit={{
								opacity: 0,
							}}
							className="fixed inset-0 bg-black-0/40"
						/>
						<motion.div className="flex-center h-full p-4 text-center" {...animations.variants.scale}>
							{children}
						</motion.div>
					</div>
				)}
			</AnimatePresence>
		)}
	</PortalWithState>
);
