import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Search } from '@/components/layouts/Search/Search';
import { Pages } from '@/pages';
import { LogoIcon } from '@/shared/static';
import { toggleBodyScrolling } from '@/shared/utils';
import { useAppSelector } from '@/store';

import type { NavItem } from './Header.const';
import { getNavigationItems, navigationConfig, NavItemIds } from './Header.const';
import { SelectLang } from './SelectLang';

const NavigationItemContent = ({ Icon, nav }: { Icon?: SvgIcon; nav: NavItem }) => (
	<>
		{Icon && (
			<Icon
				className={clsx(
					'h-6 w-6 transition-colors',
					nav.id === NavItemIds.store ? 'group-hover:stroke-orange-100' : 'group-hover:fill-orange-100',
				)}
			/>
		)}
		{nav.title && (
			<p
				className={clsx(
					'leading-4 transition-colors group-hover:text-orange-100',
					nav.id !== NavItemIds.login && nav.id !== NavItemIds.signup ? 'hidden tablet:block' : '',
				)}
			>
				{nav.title}
			</p>
		)}
	</>
);

export const Header: FC = () => {
	const location = useLocation();
	const { id } = useParams<'id'>();

	const intl = useIntl();

	const { user } = useAppSelector((state) => state.auth);

	const [showSearch, setShowSearch] = useState(false);

	const navigationItems = useMemo(() => getNavigationItems(intl), [intl]);

	const currNavigationItems = useMemo<Array<NavItem>>(() => {
		const pathname = id ? location.pathname.replace(id, ':id') : location.pathname;
		// @ts-ignore
		const currentIds = navigationConfig[pathname] as Array<NavItemIds>;
		return currentIds
			.map((navItemId) => {
				if (navItemId === NavItemIds.auth) {
					if (user) {
						return navigationItems.find((navItem) => navItem.id === NavItemIds.account)!;
					}
					return navigationItems.filter(
						(navItem) => navItem.id === NavItemIds.login || navItem.id === NavItemIds.signup,
					);
				}
				return navigationItems.find((navItem) => navItem.id === navItemId)!;
			})
			.flat()
			.map((el) => {
				const authNameCondition = el.id === NavItemIds.account && user && user.name;
				return {
					...el,
					title: authNameCondition ? user.name : el.title,
				};
			});
	}, [id, location.pathname, navigationItems, user]);

	const openSearch = () => {
		setShowSearch(true);
		toggleBodyScrolling(true);
	};

	const closeSearch = () => {
		setShowSearch(false);
		toggleBodyScrolling(false);
	};

	return (
		<div className="sticky top-0 z-50 bg-black-100">
			<Transition
				show={showSearch}
				enter="ease-in-out duration-500"
				enterFrom="translate-y-[-100vh]"
				enterTo="translate-y-[0]"
				leave="ease-in-out duration-500"
				leaveFrom="translate-y-[0]"
				leaveTo="translate-y-[-100vh]"
				className="relative z-[100]"
			>
				<Search closeSearch={closeSearch} />
			</Transition>
			<div className="customContainer relative flex h-[4rem] items-center justify-between tablet:h-[4.5rem]">
				<Link to={Pages.Home} className="flex-center space-x-3">
					<LogoIcon className="h-6 w-8 tablet:h-8 tablet:w-10" />
				</Link>
				<nav className="flex space-x-4 text-white">
					{currNavigationItems.map((item) => {
						const Icon = item.icon;
						return (
							<div key={item.id} className="group flex cursor-pointer items-center">
								{item.id === NavItemIds.search ? (
									<button className="h-6 w-6" onClick={openSearch}>
										<NavigationItemContent Icon={Icon} nav={item} />
									</button>
								) : (
									<Link to={item.href || '/'} className="flex items-center space-x-2">
										<NavigationItemContent Icon={Icon} nav={item} />
									</Link>
								)}
							</div>
						);
					})}
					<SelectLang />
				</nav>
			</div>
		</div>
	);
};
