import { DateTime } from 'luxon';

import { shortWeekdays } from '@/shared/constants';

export const formatDate = (date: Date | string, type: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 = 1, locale?: Locales): string => {
	if (typeof date === 'string') {
		if (type === 1) {
			return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
		}
		if (type === 2) {
			return date.split('.').reverse().join('-');
		}

		if (type === 4) {
			const hugeDate = DateTime.fromISO(date).setLocale('en-US').toLocaleString(DateTime.DATE_HUGE);
			const arr = hugeDate.split(', ');
			const weekday = arr[0];
			const month = arr[1].split(' ')[0];
			const day = arr[1].split(' ')[1];
			return `${day.length === 1 ? `0${day}` : day} ${month} ${weekday}`;
		}

		if (type === 5 && locale) {
			return shortWeekdays[locale][DateTime.fromISO(date).weekday];
		}

		if (type === 6) {
			const fullDate = DateTime.fromISO(date).setLocale('en-US').toLocaleString(DateTime.DATE_FULL);
			return fullDate.split(', ')[0].split(' ').reverse().join(' ');
		}

		if (type === 7) {
			const fullDate = DateTime.fromISO(date).setLocale('en-US').toLocaleString(DateTime.DATE_MED);
			return fullDate.split(', ')[0].split(' ').reverse().join(' ');
		}

		if (type === 8) {
			return DateTime.fromISO(date).setLocale('en-US').toLocaleString(DateTime.DATE_FULL);
		}
	}

	if (type === 3) {
		const isoDate = DateTime.fromJSDate(date as Date).toISO()!;
		return DateTime.fromISO(isoDate).toFormat('dd.MM.yyyy');
	}

	return typeof date === 'string' ? date : date.toString();
};

export const formatTime = (date: string) => DateTime.fromISO(date).toUTC().toFormat('HH:mm');
