import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import { BlurWrapper } from '@/components/shared';
import { errors, noop } from '@/shared/constants';
import { ArrowDownIcon } from '@/shared/static';
import { useAppSelector } from '@/store';

import type { SelectProps } from './Select.types';
import { VariantSelect } from './Select.types';

export function Select<T extends OptionItem>({
	options,
	selectedValue,
	setSelectValue,
	placeholder,
	error,
	className = '',
	variant,
}: SelectProps<T>) {
	const locale = useAppSelector((state) => state.auth.lang);

	const [open, setOpen] = useState<boolean>(false);

	const chooseOption = (opt: T) => () => {
		if (opt.id !== selectedValue?.id) {
			setSelectValue(opt);
			setOpen(false);
		}
	};

	return (
		<div className={clsx('relative', className)}>
			<BlurWrapper className="relative" onBlur={open ? () => setOpen(false) : noop}>
				<button
					type="button"
					className={clsx(
						'relative w-full px-3 py-2 text-left transition-all',
						variant === VariantSelect.calculator && 'bg-orange-100',
						variant === VariantSelect.default && 'border bg-white',
						error ? 'border-red-900 text-red-900' : 'border-brown-400/70',
						open ? 'rounded-t-lg' : 'rounded-lg',
					)}
					onClick={() => setOpen((prev) => !prev)}
				>
					<span
						className={clsx(
							'-mt-[1px] block truncate',
							error
								? 'text-red-900'
								: selectedValue && variant === VariantSelect.default
								? 'text-brown-400'
								: 'text-brown-400/50',
							selectedValue && variant === VariantSelect.calculator && 'text-white',
						)}
					>
						{selectedValue ? selectedValue.title : placeholder}
					</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
						<ArrowDownIcon
							className={clsx(
								'h-4 w-4 transition-transform',
								open && 'rotate-180',
								error && 'stroke-red-900',
								variant === VariantSelect.calculator && 'stroke-white',
							)}
							aria-hidden="true"
						/>
					</span>
				</button>
				<AnimatePresence>
					{open && (
						<motion.div
							className={clsx(
								'absolute z-50 w-full overflow-hidden rounded-b-lg border-b border-l border-r bg-white shadow-[0_3px_4px_0_rgba(0,0,0,0.15)]',
								error ? 'border-red-900' : 'border-brown-400/70',
							)}
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: 'auto', opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
							transition={{
								type: 'spring',
								stiffness: 260,
								damping: 20,
								duration: 0.3,
							}}
						>
							<motion.div
								initial={{ y: -200, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: -200, opacity: 0 }}
								transition={{
									type: 'spring',
									stiffness: 260,
									damping: 20,
									duration: 0.3,
								}}
							>
								{!options.length && (
									<p className="cursor-default px-3 py-3 text-center text-amber-900">No data</p>
								)}
								{!!options.length &&
									options.map((opt) => (
										<button
											key={opt.id}
											type="button"
											className={clsx(
												'group block w-full px-3 py-3 text-left transition-colors',
												selectedValue && selectedValue.id === opt.id
													? 'cursor-default bg-amber-100'
													: 'cursor-pointer hover:bg-brown-400/5',
											)}
											onClick={chooseOption(opt)}
										>
											<span
												className={clsx(
													'truncate transition-colors',
													selectedValue && selectedValue.id === opt.id
														? 'font-medium text-amber-900'
														: 'text-gray-900',
												)}
											>
												{opt.title}
											</span>
										</button>
									))}
							</motion.div>
						</motion.div>
					)}
				</AnimatePresence>
			</BlurWrapper>
			{error && (
				<p className="ml-3 mt-[2px] text-sm text-red-900">
					<span className="font-medium">{errors.yup[locale].required}</span>
				</p>
			)}
		</div>
	);
}
